import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function ServiceArea({ data }) {
  return (
    <Layout>
      <Seo title="Service Area" />
      <h1 className="pt-4 pb-2 my-4 text-3xl font-extrabold text-center text-blue sm:text-4xl">
        Service Area
      </h1>
      <div className="block px-4 pb-8 mx-auto max-w-7xl lg:px-8">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=zc4eXp97t9_w.ki7AO2Ro0WS4&amp;z=9"
          title="Service Area for Alpine Air Conditioning"
          className="w-full"
          height="500"
        ></iframe>
      </div>
      <div className="block px-4 space-y-4 sm:space-y-0 pb-8 mx-auto max-w-7xl gap-4 sm:grid sm:grid-cols-4">
        <div className="px-4 bg-white border border-gray-100 rounded-lg shadow-xl">
          <h2 className="pt-4 pb-2 my-4 text-2xl font-extrabold text-center text-blue">
            Amador County
          </h2>
          <ul className="mb-4 ml-16 list-disc space-y-1">
            <li>Amador City</li>
            <li>Ione</li>
            <li>Jackson</li>
            <li>Lake Comanche</li>
            <li>Pine Grove</li>
            <li>Pioneer</li>
            <li>Plymouth</li>
            <li>Sutter Creek</li>
          </ul>
        </div>
        <div className="px-4 bg-white border border-gray-100 rounded-lg shadow-xl">
          <h2 className="pt-4 pb-2 my-4 text-2xl font-extrabold text-center text-blue">
            Alpine County
          </h2>
          <ul className="mb-4 ml-16 list-disc space-y-1">
            <li>Kirkwood</li>
          </ul>
        </div>
        <div className="px-4 bg-white border border-gray-100 rounded-lg shadow-xl">
          <h2 className="pt-4 pb-2 my-4 text-2xl font-extrabold text-center text-blue">
            El Dorado County
          </h2>
          <ul className="mb-4 ml-16 list-disc space-y-1">
            <li>South Lake Tahoe</li>
            <li>Placerville</li>
          </ul>
        </div>
        <div className="px-4 bg-white border border-gray-100 rounded-lg shadow-xl">
          <h2 className="pt-4 pb-2 my-4 text-2xl font-extrabold text-center text-blue">
            Calaveras County
          </h2>
          <ul className="mb-4 ml-16 list-disc space-y-1">
            <li>Fiddletown</li>
            <li>Lake Comanche</li>
            <li>Mokelumne Hill</li>
            <li>Valley Springs</li>
            <li>West Point</li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
